import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Menu from './Menu';

const Header: React.FC = () => (
  <div className="relative">
    <Menu />
    <div className="absolute z-20 top-1/2 left-1/2 -translate-x-1/2 text-xl">
      <p className="min-w-max font-project sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl text-white text-center shadowText">
        LUC'S SPECIAL PROJECTS
      </p>
      <span className="hidden sm:block">
        <p className="min-w-max font-project p-5 sm:text-1xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl text-white text-center shadowText">
          Luc Van der Stricht
        </p>
      </span>
    </div>

    <div>
      <div className="absolute z-10 w-full h-full bg-black/60 rounded-t-xl" />
      <StaticImage
        src="../images/heroImage.png"
        alt="Hero Image"
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={16 / 9}
        className="rounded-t-xl"
      />
    </div>
  </div>
);

export default Header;
